import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'

import Button from 'components/Button'

import style from './BuyManageSell.style'

const BuyManageSellStyled = styled.div(props => ({ ...style(props) }))

const BuyManageSell = ({ t }) => {
    return (
        <BuyManageSellStyled>
            <Button white primary link="/buy-a-yacht">
                {t('buy')}
            </Button>
            <Button white primary link="/manage">
                {t('manage')}
            </Button>
            <Button white primary link="/sell">
                {t('sell')}
            </Button>
        </BuyManageSellStyled>
    )
}

BuyManageSell.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('common')(withTheme(BuyManageSell))
