import { toRem } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, denseSubtitle }) => ({
    background:
        'url(https://res.cloudinary.com/merkandmerk/image/upload/v1605776264/Static/newsletter-bg-3_jz1g2k.jpg) no-repeat center center',
    minHeight: 400,
    padding: toRem(40),
    borderRadius: 4,

    [theme.breakpoints.up('md')]: {
        minHeight: 0,
        padding: toRem(65),
        background:
            'url(https://res.cloudinary.com/merkandmerk/image/upload/v1605776264/Static/newsletter-bg-3_jz1g2k.jpg) no-repeat right -700px center'
    },

    [theme.breakpoints.up('lg')]: {
        background:
            'url(https://res.cloudinary.com/merkandmerk/image/upload/v1605776264/Static/newsletter-bg-3_jz1g2k.jpg) no-repeat center center'
    },

    '& .title': {
        fontSize: toRem(36),
        color: theme.colors.goldLight,
        fontWeight: 500,

        [theme.breakpoints.up('md')]: {
            fontSize: toRem(50),
            fontWeight: 600
        }
    },

    '& .subtitle': {
        fontWeight: 500,
        marginBottom: toRem(20),
        ...(denseSubtitle && {
            maxWidth: 600
        })
    }
})

export default style
