import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation, Link } from 'server/i18n'

import style from './BuyManageCta.style'

const BuyManageCtaStyled = styled.div(props => ({ ...style(props) }))

const BuyManageCta = ({ t }) => {
    return (
        <BuyManageCtaStyled>
            <div className="bmcText">
                <h2>{t('buy-manage-title')}</h2>
                <h4>{t('buy-manage-subtitle')}</h4>
            </div>

            <div className="bmcButtons">
                <Link href="/buy-a-yacht">
                    <div className="ctaButton">
                        <h4>{t('buy')}</h4>
                        <p>{t('buy-cta')}</p>
                        <img src="/icons/arrow-cta.svg" alt={t('buy')} />
                    </div>
                </Link>
                <Link href="/manage">
                    <div className="ctaButton">
                        <h4>{t('manage')}</h4>
                        <p>{t('manage-cta')}</p>
                        <img src="/icons/arrow-cta.svg" alt={t('manage')} />
                    </div>
                </Link>
                <Link href="/sell">
                    <div className="ctaButton">
                        <h4>{t('sell')}</h4>
                        <p>{t('sell-cta')}</p>
                        <img src="/icons/arrow-cta.svg" alt={t('sell')} />
                    </div>
                </Link>
            </div>
        </BuyManageCtaStyled>
    )
}

BuyManageCta.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('common')(withTheme(BuyManageCta))
