import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import style from './TextBlock.style'

const TextBlockStyled = styled.div(props => ({ ...style(props) }))

const TextBlock = ({ text }) => {
    return <TextBlockStyled dangerouslySetInnerHTML={{ __html: text }} />
}

TextBlock.propTypes = {
    text: PropTypes.string.isRequired
}

export default withTheme(TextBlock)
