import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    margin: '0 auto 0 auto',
    maxWidth: 1380,
    padding: 0,
    overflow: 'hidden',

    [theme.breakpoints.up('md')]: {
        padding: toRems([0, 20])
    },

    '& .NewsSlider': {
        width: '100%',
        marginRight: toRem(40),
        overflow: 'initial',

        [theme.breakpoints.up('md')]: {
            '& .swiper-wrapper': {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gridColumnGap: toRem(40)
            }
        }
    },

    '& .NewsSlide': {
        display: 'flex',
        paddingRight: toRem(20),

        [theme.breakpoints.up('md')]: {
            width: '100% !important',
            paddingRight: 0,

            '> div': {
                width: '100%'
            }
        }
    }
})

export default style
