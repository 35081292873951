import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'
import { Flex } from 'reflexbox'
import layoutTheme from 'theme/layout-theme'
import BlogPostCard from 'components/BlogPostCard'
import { useUIDSeed } from 'react-uid'

import { useLayoutEffect } from 'react'
import Swiper from 'swiper'
import style from './NewsTeaser.style'

const NewsTeaserStyled = styled.div(props => ({ ...style(props) }))

const NewsTeaser = ({ t, posts }) => {
    const news = posts.slice(0, 3)
    const uid = useUIDSeed()

    useLayoutEffect(() => {
        // eslint-disable-next-line no-unused-vars
        const mySwiper = new Swiper('.NewsSlider', {
            slidesPerView: 1.2,
            initialSlide: 0,
            simulateTouch: true
        })
    })

    return (
        <NewsTeaserStyled>
            <Flex
                theme={layoutTheme}
                px={20}
                mt={[35, null, 70]}
                mb={[35, null, 70, 80]}
                variant="center1600"
                flexWrap="wrap"
                justifyContent="space-between"
                flexDirection={['column', null, 'row']}>
                <div className="swiper-container NewsSlider">
                    <div className="swiper-wrapper">
                        {news.map(post => (
                            <div key={uid(post)} className="NewsSlide swiper-slide">
                                <BlogPostCard isHomepage post={post} />
                            </div>
                        ))}
                    </div>
                </div>
            </Flex>
        </NewsTeaserStyled>
    )
}

NewsTeaser.propTypes = {
    t: PropTypes.func.isRequired,
    posts: PropTypes.shape([]).isRequired
}
export default withTranslation('common')(withTheme(NewsTeaser))
