import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'

import Button from 'components/Button'

import style from './NewsletterPromo.style'

const NewsletterPromoStyled = styled.div(props => ({ ...style(props) }))

const NewsletterPromo = ({ t, title, subtitle, buttonLabel, link, denseSubtitle }) => {
    return (
        <NewsletterPromoStyled denseSubtitle={denseSubtitle}>
            <h5 className="title">{title || t('newsletter')}</h5>
            <p className="subtitle">{subtitle || t('newsletter-subscribe-text-alt')}</p>
            <Button variation="secondary" link={link || '/newsletter'}>
                {buttonLabel || t('controls:sign-up')}
            </Button>
        </NewsletterPromoStyled>
    )
}

NewsletterPromo.propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonLabel: PropTypes.string,
    link: PropTypes.string,
    denseSubtitle: PropTypes.bool
}
NewsletterPromo.defaultProps = {
    title: null,
    subtitle: null,
    buttonLabel: null,
    link: null,
    denseSubtitle: false
}
export default withTranslation('common', 'controls')(withTheme(NewsletterPromo))
