import { toRem } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .bmcText': {
        width: '95%',
        '& h4': {
            color: theme.colors.text.goldDark,
            fontWeight: 200
        },

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },

    [theme.breakpoints.up('md')]: {
        paddingLeft: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',

        '& .bmcText': {
            width: '45%'
        },

        '& .bmcButtons': {
            width: '49%'
        }
    },

    [theme.breakpoints.up('lg')]: {
        paddingLeft: 20
    },

    '& .bmcButtons': {
        width: '99%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            marginTop: 0
        },

        '& .ctaButton': {
            background: '#00354F',
            border: '1px solid',
            borderColor: theme.colors.blueDark,
            padding: 20,
            width: '99%',

            [theme.breakpoints.up('md')]: {
                width: '33%'
            },

            '&:hover': {
                background: theme.colors.blueLight,
                cursor: 'pointer',
                '& h4': {
                    color: theme.colors.text.goldDark
                },
                paddingTop: 16
            },

            '& p': {
                fontSize: toRem(14),
                marginBottom: 20
            },

            '& img': {
                float: 'right'
            }
        }
    }
})

export default style
