import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, isHomepage }) => ({
    marginBottom: toRem(20),
    position: 'relative',

    '& .block-link': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2,
        textIndent: -9999
    },

    [theme.breakpoints.up('md')]: {
        width: isHomepage ? '30%' : '48%',
        display: 'flex',
        flexDirection: 'column'
    },

    [theme.breakpoints.up('lg')]: {
        marginBottom: toRem(40)
    },

    [theme.breakpoints.up('xl')]: {
        marginBottom: toRem(80)
    },

    '&.first': {
        '& .content p': {
            display: 'block'
        },

        [theme.breakpoints.up('md')]: {
            width: '100%',
            flexDirection: 'row',

            '& .image': {
                width: '48%',
                display: 'flex',
                height: 'auto',
                borderRadius: '4px 0 0 4px'
            },

            '& .content': {
                width: '52%',
                borderRadius: '0px 4px 4px 0'
            }
        },

        [theme.breakpoints.up('lg')]: {
            '& .content': {
                padding: toRem(60),

                h3: {
                    fontSize: toRem(28)
                },

                p: {
                    fontSize: toRem(18)
                }
            }
        },
        [theme.breakpoints.up('xl')]: {
            '& .content': {
                padding: toRems([125, 80]),

                h3: {
                    fontSize: toRem(35)
                },

                p: {
                    fontSize: toRem(24),
                    marginBottom: toRem(50)
                }
            }
        }
    },

    '& .image': {
        overflow: 'hidden',
        borderRadius: '4px 4px 0 0',
        height: toRem(185),

        [theme.breakpoints.down('sm')]: {
            img: {
                height: '100%',
                width: '100%',
                objectFit: 'cover'
            }
        },

        [theme.breakpoints.up('md')]: {
            height: isHomepage ? 100 : 150,
            position: 'relative',
            img: {
                height: '100%',
                width: '100%',
                objectPosition: '50% 50%',
                objectFit: 'cover'
            }
        },
        [theme.breakpoints.up('lg')]: {
            height: isHomepage ? 150 : 200
        },
        [theme.breakpoints.up('xl')]: {
            height: isHomepage ? 200 : 300
        }
    },

    '& .content': {
        background: theme.colors.blueLight,
        padding: toRem(30),
        borderRadius: '0px 0 4px 4px',
        flex: 1,

        [theme.breakpoints.up('lg')]: {
            padding: toRem(50)
        },

        '.cat': {
            display: 'block',
            textTransform: 'uppercase',
            fontSize: toRem(14)
        },

        h3: {
            color: theme.colors.goldLight,
            fontSize: toRem(20),
            fontWeight: 400,
            marginBottom: toRem(22),

            [theme.breakpoints.up('lg')]: {
                fontSize: toRem(24)
            }
        },

        p: {
            fontSize: toRem(14),
            marginBottom: toRem(15),
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'block',
                fontSize: toRem(16)
            }
        },

        '& .read-more': {
            color: theme.colors.goldLight,
            fontSize: toRem(14),
            display: 'inline-flex',
            alignItems: 'center',

            [theme.breakpoints.up('lg')]: {
                fontSize: toRem(16)
            },

            '& svg path': {
                fill: theme.colors.goldLight
            }
        }
    }
})

export default style
