import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'

import style from './Image.style'

const ImageStyled = styled.img(props => ({ ...style(props) }))

const Image = ({ src, alt, title, className, onClick }) => {
    const errorFallbackSrc = e => {
        e.target.src = '/images/fallback/error.svg'
    }
    return (
        <ImageStyled
            src={src}
            alt={alt || 'Merk&Merk'}
            title={title}
            className={className}
            onError={errorFallbackSrc}
            onClick={() => onClick()}
            loading='lazy'
        />
    )
}

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
}

Image.defaultProps = {
    src: undefined,
    alt: undefined,
    title: undefined,
    className: undefined,
    onClick: undefined
}

export default withTranslation('common')(withTheme(Image))
