import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        padding: '0 20px'
    },
    '& .TheMap': {
        height: 699,
        marginBottom: toRem(20),
        margin: `0 auto ${toRem(20)} auto`,
        position: 'relative',
        background:
            'url(https://res.cloudinary.com/merkandmerk/image/upload/v1604585453/Static/newest-map_ic59v3.jpg) no-repeat center center',

        '& .block-link': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            textIndent: -9999
        },

        [theme.breakpoints.up('lg')]: {
            borderRadius: 4,
            overflow: 'hidden'
        }
    }
})

export default style
