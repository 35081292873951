import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    p: {
        fontSize: toRem(14),
        marginBottom: toRem(20),

        '&:last-of-type': {
            marginBottom: 0
        },

        [theme.breakpoints.up('lg')]: {
            fontSize: toRem(16),
            lineHeight: '28px'
        }
    },

    address: {
        fontSize: toRem(14),
        fontStyle: 'normal'
    }
})

export default style
