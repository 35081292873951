import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'

import style from './Map.style'

const MapStyled = styled.div(props => ({ ...style(props) }))

const Map = ({ t }) => {
    return (
        <MapStyled>
            <div className="TheMap">
                <a
                    href="https://www.google.com/maps/place/Merk+%26+Merk+Yacht+Service+Tribunj+d.o.o./@43.7532669,15.7463043,17z/data=!3m1!4b1!4m5!3m4!1s0x1334d827f6c3b605:0x94167fc4a424b6ed!8m2!3d43.753263!4d15.748493"
                    className="block-link"
                    target="_blank"
                    rel="noreferrer noopener">
                    {t('click-on-map')}
                </a>
            </div>
        </MapStyled>
    )
}

Map.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation('common')(withTheme(Map))
