import { useMemo, useContext, useEffect } from 'react'
import useFetch from '@hmn/use-fetch'
import AppContext from 'contexts/AppContext'
import { getBoatsListingApiURL } from 'helpers/middleware'

const isSameData = (prev, next) => {
    let isSame = true
    if (prev.length !== next.length || !prev.every(({ id }, index) => next[index] && next[index].id === id)) {
        isSame = false
    }
    return isSame
}

const useBoatList = (ssrData = []) => {
    const { boatListCache, setBoatListCache } = useContext(AppContext)
    const { result, pending, error } = useFetch(getBoatsListingApiURL())

    useEffect(() => {
        if (ssrData.length && !isSameData(boatListCache, ssrData)) {
            setBoatListCache(ssrData)
        }
    }, [])

    useEffect(() => {
        if (result && result.length && !isSameData(boatListCache, result)) {
            setBoatListCache(result)
        }
    }, [result, boatListCache])

    const initialData = useMemo(() => {
        if (boatListCache && boatListCache.length && !ssrData.length) {
            return boatListCache
        }
        return ssrData
    }, [boatListCache])

    const data = useMemo(() => {
        if (result && result.length && !isSameData(initialData, result)) {
            return result
        }
        return initialData
    }, [result, initialData])

    const isLoading = useMemo(() => {
        return !data.length && pending
    }, [data, pending])

    const hasError = useMemo(() => {
        return !data.length && !isLoading && !!error
    }, [data, isLoading, error])

    return {
        data,
        isLoading,
        hasError
    }
}

export default useBoatList
