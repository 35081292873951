import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation, Link } from 'server/i18n'
import Image from 'components/Image'

import ArrowIcon from 'public/icons/arrow-right.svg'
import style from './BlogPostCard.style'

const BlogPostCardStyled = styled.div(props => ({ ...style(props) }))

const BlogPostCard = ({ t, isFirst, post, isHomepage }) => {
    return (
        <BlogPostCardStyled isHomepage className={isFirst ? 'first' : ''}>
            <Link href={`/yacht-news/${post.categories.nodes[0].slug}/${post.slugEn.slug}`}>
                <a className="block-link">Link to post</a>
            </Link>
            <div className="image">
                <Image src={post.featuredImage.node.sourceUrl} />
            </div>
            <div className="content">
                <span className="cat">{post.categories.nodes[0].name}</span>

                <h3>{post.title}</h3>

                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />

                <Link href={`/yacht-news/${post.categories.nodes[0].slug}/${post.slugEn.slug}`}>
                    <a className="read-more">
                        {t('read-more')} <ArrowIcon />
                    </a>
                </Link>
            </div>
        </BlogPostCardStyled>
    )
}

BlogPostCard.propTypes = {
    t: PropTypes.func.isRequired,
    post: PropTypes.shape({
        title: PropTypes.string,
        excerpt: PropTypes.string,
        categories: PropTypes.shape({
            nodes: PropTypes.arrayOf(
                PropTypes.shape({
                    slug: PropTypes.string,
                    name: PropTypes.string
                })
            )
        }),
        featuredImage: PropTypes.shape({
            node: PropTypes.shape({
                sourceUrl: PropTypes.string
            })
        }),
        slugEn: PropTypes.shape({
            slug: PropTypes.string
        })
    }).isRequired,
    isFirst: PropTypes.bool,
    isHomepage: PropTypes.bool
}

BlogPostCard.defaultProps = {
    isFirst: false,
    isHomepage: false
}

export default withTranslation('common')(withTheme(BlogPostCard))
