import { toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    maxWidth: 800,
    paddingTop: 40,
    fontWeight: 700,

    [theme.breakpoints.up('md')]: {
        paddingTop: 0,
        textAlign: 'right',
        marginLeft: 'auto'
    },

    [theme.breakpoints.down('sm')]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',

        '& > a.primary': {
            minWidth: 'auto',
            padding: toRems([15, 0])
        }
    }

    // [theme.breakpoints.up('lg')]: {
    //     paddingLeft: 20
    // }
})

export default style
