import { toRem } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    position: 'relative',
    height: 'calc(100vh - 80px)',

    '.Homepage-video': {
        right: 0,
        bottom: 0,
        width: '100%',
        position: 'absolute',
        height: '100%',
        objectFit: 'cover'
    },

    '.Homepage-content': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '40px 20px',
        zIndex: 50,

        [theme.breakpoints.down('sm')]: {
            '& .title': {
                maxWidth: toRem(320)
            }
        },

        [theme.breakpoints.up('md')]: {
            position: 'relative'
        }
    },

    [theme.breakpoints.up('md')]: {
        padding: '60px 40px',

        '.Homepage-cta': {
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            justifyContent: 'space-between'
        }
    }
})

export default style
