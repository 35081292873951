import { useMemo, useContext, useEffect, useState } from 'react'
import useFetch from '@hmn/use-fetch'
import AppContext from 'contexts/AppContext'
import { getBoatApiURL } from 'helpers/middleware'

const useBoat = (slug, ssrData = null) => {
    const { boatListCache } = useContext(AppContext)
    const { result, pending, error, run } = useFetch(getBoatApiURL(slug), undefined, false)
    const [isLoadingManualy, setIsLoadingManualy] = useState(false)

    const initialData = useMemo(() => {
        if (ssrData && ssrData.slug === slug) {
            return ssrData
        }
        if (boatListCache && boatListCache.length) {
            return boatListCache.find(item => item.published && item.slug === slug)
        }
        return null
    }, [boatListCache, slug])

    const data = useMemo(() => {
        if (initialData && initialData.slug === slug) {
            return initialData
        }
        if (result && result.slug === slug) {
            return result
        }
        return null
    }, [result, initialData, slug])

    const isLoading = useMemo(() => {
        if (!data) {
            return pending
        }
        return data.slug !== slug || isLoadingManualy
    }, [data, pending, isLoadingManualy, slug])

    const hasError = useMemo(() => {
        return !isLoading && !!error
    }, [isLoading, error])

    useEffect(() => {
        if (!isLoadingManualy) {
            return () => {}
        }
        run()
        const timeout = setTimeout(() => {
            setIsLoadingManualy(false)
        })
        return () => {
            clearTimeout(timeout)
        }
    }, [isLoadingManualy])

    useEffect(() => {
        setIsLoadingManualy(true)
    }, [slug])

    return {
        data,
        isLoading,
        hasError
    }
}

export default useBoat
