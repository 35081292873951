/* eslint-disable quote-props */
const style = ({ theme }) => ({
    img: {
        maxWidth: '100%',
        verticalAlign: 'middle',
        border: 'none',
        outline: 'none'
    }
})

export default style
